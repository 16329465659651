/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import CoverImage from "../images/cover.jpeg"

function SEO({ description, meta, title, canonical, author, image, jsonld }) {
  const lang = "pt-BR"
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const pageTitle = title
    ? `${title} - Apetrecho Digital`
    : site.siteMetadata.title
  const pageCanonical = `${site.siteMetadata.siteUrl}/${canonical}`
  const metaImage = image ? image : `${site.siteMetadata.siteUrl}${CoverImage}`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={pageTitle}
      link={[
        {
          rel: `canonical`,
          href: pageCanonical,
        },
      ]}
      meta={[
        {
          name: `viewport`,
          content: `width=device-width, initial-scale=1.0`,
        },
        {
          name: `robots`,
          content: `index,follow`,
        },
        {
          name: `publisher`,
          content: `Apetrecho Digital`,
        },
        {
          name: `author`,
          content: author,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    >
      {jsonld && (
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  meta: [],
  description: ``,
  canonical: ``,
  author: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  canonical: PropTypes.string.isRequired,
  author: PropTypes.string,
  image: PropTypes.string,
  jsonld: PropTypes.object,
}

export default SEO
