/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import Header from "./header"
import Footer from "./footer"
import "./layout.css"
import theme from "../consts/theme"
import "@fontsource/nunito"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faDownload,
  faBars,
  faTimes,
  faBell,
  faAngleRight,
  faArrowRight,
  faCheck,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faDownload,
  faBars,
  faTimes,
  faBell,
  faAngleRight,
  faArrowRight,
  faCheck,
  faArrowLeft
)

const Layout = ({ children, headerSize }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = data.site.siteMetadata?.title || `Apetrecho Digital`

  return (
    <>
      <ThemeProvider theme={theme}>
        <div>
          <Header siteTitle={siteTitle} size={headerSize} />
          <div>
            <main>{children}</main>
          </div>
          <Footer siteTitle={siteTitle} />
        </div>
      </ThemeProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerSize: PropTypes.string,
}

Layout.defaultProps = {
  headerSize: "small",
}

export default Layout
