import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import theme from "../consts/theme"

const SC = {}

const Container = ({
  isFlex,
  flexDirection,
  alignItems,
  justifyContent,
  children,
  containerWidth,
  className,
}) => {
  return (
    <SC.Container
      isFlex={isFlex}
      flexDirection={flexDirection}
      alignItems={alignItems}
      justifyContent={justifyContent}
      className={className}
      containerWidth={containerWidth}
    >
      {children}
    </SC.Container>
  )
}

SC.Container = styled.div`
  width: ${({ containerWidth }) => containerWidth};
  height: 100%;
  display: ${({ isFlex }) => (isFlex ? "flex" : "block")};
  flex-direction: ${({ isFlex, flexDirection }) => isFlex && flexDirection};
  align-items: ${({ isFlex, alignItems }) => isFlex && alignItems};
  justify-content: ${({ isFlex, justifyContent }) => isFlex && justifyContent};
  margin: 0 auto;
  position: relative;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
  }
`

Container.propTypes = {
  isFlex: PropTypes.bool,
  flexDirection: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  containerWidth: PropTypes.string,
}

Container.defaultProps = {
  isFlex: false,
  flexDirection: "unset",
  alignItems: "unset",
  justifyContent: "unset",
  containerWidth: theme.sizes.DEFAULT_CONTAINER,
}

export default Container
