export default {
  colors: {
    PRIMARY_PURPLE: "#383294",
    SECONDARY_PURPLE: "#493AA1",
    RED_1: "#C65C5C",
    DARK_RED: "#A32121",
    PRIMARY_GREEN: "#68B1A6",
    SECONDARY_GREEN: "#5C948B",
    GREEN_1: "#5ECA53",
    GREY_1: "#37373C",
    GREY_2: "#dedee1",
    GREY_3: "#555555",
    GREY_4: "#62626c",
    GREY_5: "#E7E7E7",
    GREY_6: "#999999",
    APRENDIZAGEM: "#D14719",
    ESTUDOS: "#DCAC06",
    SOFT_SKILLS: "#2A688C",
    COMPORTAMENTO: "#0FBF81",
  },
  fonts: {
    NUNITO: "Nunito",
  },
  sizes: {
    DEFAULT_CONTAINER: "1300px",
    MEDIUM_CONTAINER: "1060px",
    DEFAULT_BORDER_RADIUS: "5px",
    ROUNDED_BORDER_RADIUS: "65px",
  },
  breakpoints: {
    XS: "575.98px",
  },
}
