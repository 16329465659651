import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Container from "./container"
import PropTypes from "prop-types"
import LogoPurple from "../images/logo-purple.svg"

const SC = {}

const Footer = ({ siteTitle }) => (
  <SC.Footer>
    <SC.Container
      isFlex
      alignItems="center"
      justifyContent="space-between"
      flexDirection="row"
    >
      <SC.LinksWrapper>
        <SC.LogoLink to="/">
          <img src={LogoPurple} alt={siteTitle} />
        </SC.LogoLink>
        <SC.Link to="/quem-somos">Quem somos</SC.Link>
        <SC.Link to="/contato">Contato</SC.Link>
        <SC.Link to="/politica-de-privacidade">Política de Privacidade</SC.Link>
      </SC.LinksWrapper>
      <SC.Copyright>
        Copyright © 2021 Apetrecho Digital. Todos os direitos reservados
      </SC.Copyright>
    </SC.Container>
  </SC.Footer>
)

SC.Footer = styled.footer`
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
  height: 60px;
  color: #fff;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.NUNITO};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    width: 100%;
    height: auto;
  }
`

SC.Container = styled(Container)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    padding: 15px 25px;
  }
`

SC.LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    flex-direction: column;
    margin: 15px 0;
  }
`

SC.Link = styled(Link)`
  color: #fff;
  margin-right: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    margin: 3px 0;
    padding: 5px 0;
  }

  &:hover {
    text-decoration: underline;
  }
`

SC.LogoLink = styled(SC.Link)`
  height: 50px;
  margin-right: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: 70px;
    margin-bottom: 15px;
    margin-right: 0;
    padding: 0;
  }

  img {
    height: 100%;
  }
`

SC.Copyright = styled.span`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    text-align: center;
    display: block;
  }
`

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
