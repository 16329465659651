import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import Container from "./container"
import LogoPurpleFull from "../images/logo-purple-full.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SC = {}

const Header = ({ siteTitle, size }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <SC.Header size={size}>
      <Container
        isFlex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <SC.Link to="/">
          <SC.LogoImg src={LogoPurpleFull} alt={siteTitle} />
        </SC.Link>

        <SC.ToggleMenuMobile
          to="#"
          onClick={() => setMobileMenuOpen(true)}
          aria-label="Toggle Menu"
        >
          <FontAwesomeIcon icon="bars" color="#fff" />
        </SC.ToggleMenuMobile>
        <SC.MobileMenu open={mobileMenuOpen}>
          <SC.CloseMobileMenu
            aria-label="Close Menu"
            onClick={() => setMobileMenuOpen(false)}
          >
            <FontAwesomeIcon icon="times" color="#000" />
          </SC.CloseMobileMenu>
          <SC.LinkMobileMenu to="/">Home</SC.LinkMobileMenu>
          <SC.LinkMobileMenu to="/artigos/">Artigos</SC.LinkMobileMenu>
          <SC.LinkMobileMenu to="/aprendizagem/">
            Aprendizagem
          </SC.LinkMobileMenu>
          <SC.LinkMobileMenu to="/estudos/">Estudos</SC.LinkMobileMenu>
          <SC.LinkMobileMenu to="/soft-skills/">Soft Skills</SC.LinkMobileMenu>
          <SC.LinkMobileMenu to="/comportamento/">
            Comportamento
          </SC.LinkMobileMenu>
        </SC.MobileMenu>

        <SC.LinksWrapper>
          <SC.Link to="/artigos/">Artigos</SC.Link>
          <SC.Link to="/aprendizagem/">Aprendizagem</SC.Link>
          <SC.Link to="/estudos/">Estudos</SC.Link>
          <SC.Link to="/soft-skills/">Soft Skills</SC.Link>
          <SC.Link to="/comportamento/">Comportamento</SC.Link>
        </SC.LinksWrapper>
      </Container>
    </SC.Header>
  )
}

SC.Header = styled.header`
  width: 100%;
  height: ${({ size }) => (size === "big" ? "100px" : "60px")};
  background-color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    height: 50px;
    padding: 0 25px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.SECONDARY_PURPLE};
    position: fixed;
    top: 0;
    z-index: 9999;
  }
`

SC.LogoImg = styled.img`
  height: 100%;
`

SC.LinksWrapper = styled.div`
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: none;
  }
`

SC.CloseMobileMenu = styled.button`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    position: absolute;
    right: 30px;
    top: 30px;
    background: none;
    border: none;
    font-size: 28px;
  }
`

SC.ToggleMenuMobile = styled.button`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: block;
    font-size: 26px;
    border: none;
    background: none;
  }
`

SC.MobileMenu = styled.div`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    top: 0;
    right: ${({ open }) => (open ? "0" : "-100vw")};
    padding: 0 35px;
    z-index: 999;
    transition: all 0.25s ease-in-out;
  }
`

SC.Link = styled(Link)`
  font-family: ${({ theme }) => theme.fonts.NUNITO};
  margin-right: 25px;
  font-size: 16px;
  color: #fff;
  height: calc(100% - 15px);

  &:last-of-type {
    margin-right: 0;
  }
`

SC.LinkMobileMenu = styled(SC.Link)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.XS}) {
    color: ${({ theme }) => theme.colors.PRIMARY_PURPLE};
    font-size: 24px;
    font-weight: bold;
    height: auto;
    margin: 15px 0;
    display: block;
  }
`

Header.propTypes = {
  siteTitle: PropTypes.string,
  size: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  size: "small",
}

export default Header
